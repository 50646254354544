import "./index.css";
import React, { useEffect, useState } from "react";
import screen0 from "./images/screen0.svg";
import screen1 from "./images/screen1.svg";
import screen2 from "./images/screen2.svg";
import screen3 from "./images/screen3.svg";
import screen4 from "./images/screen4.svg";
import screen5 from "./images/screen5.svg";
import screen6 from "./images/screen6.svg";
import screen7 from "./images/screen7.svg";
import screen8 from "./images/screen8.svg";
import screen9 from "./images/screen9.svg";

import guidance0 from "./images/guidance0.svg";
import guidance1 from "./images/guidance1.svg";
import guidance2 from "./images/guidance2.svg";

function App() {
  // start timer to count seconds from initializing
  const [startTime, setStartTime] = useState(new Date().getTime());
  const screenList = [
    screen0,
    screen1,
    screen2,
    screen3,
    screen4,
    screen5,
    screen6,
    screen7,
    screen8,
    screen9,
  ];
  const [screen, setScreen] = useState(screen0);
  const [guidance, toggleGuidance] = useState(false);

  useEffect(() => {
    if (screen === screen0) {
      const timerInterval = setInterval(function () {
        const currentTime = new Date().getTime();
        const changeInTime = (currentTime - startTime) / 1000;
        if (changeInTime > 2) {
          setScreen(screen1);
        }
      }, 100);
      return () => clearInterval(timerInterval);
    }
  }, [screen]);

  const handleClick = () => {
    // increment screen to next screen in screenList
    const currentScreen = screenList.indexOf(screen);
    const nextScreen = currentScreen + 1;
    if (nextScreen < screenList.length) {
      setScreen(screenList[nextScreen]);
    }
    if (nextScreen === 7 || nextScreen === 8 || nextScreen === 9) {
      toggleGuidance(true);
    }
  };

  const handleGuidanceClick = () => {
    toggleGuidance(false);
  };

  const ClickRegions = () => {
    if (screenList.indexOf(screen) < 5) {
      return (
        <div
          className="h-20 absolute opacity-0 w-64 left-20 top-0 mt-type1"
          onClick={handleClick}
        ></div>
      );
    } else if (
      screenList.indexOf(screen) === 5 ||
      screenList.indexOf(screen) === 6
    ) {
      return (
        <div
          className="h-80 absolute opacity-0 w-full top-0 mt-60"
          onClick={handleClick}
        ></div>
      );
    } else if (screenList.indexOf(screen) === 7) {
      return (
        <div className="w-full h-full absolute top-0 left-0">
          <div
            className="h-20 opacity-0 w-auto mx-5 mt-type2"
            onClick={handleClick}
          ></div>
        </div>
      );
    } else if (screenList.indexOf(screen) === 8) {
      return (
        <div className="w-full h-full absolute top-0 left-0">
          <div
            className="h-80 opacity-0 w-auto mx-0 mt-type3"
            onClick={handleClick}
          ></div>
        </div>
      );
    } else if (screenList.indexOf(screen) === 9) {
      return (
        <div className="w-full h-full absolute top-0 left-0">
          <div
            className="h-20 opacity-0 w-auto mx-5 mt-type4"
            onClick={handleClick}
          ></div>
        </div>
      );
    }
  };

  const GuidanceRegion = () => {
    if (screenList.indexOf(screen) === 7 && guidance) {
      return (
        <img
          alt="img"
          src={guidance0}
          className="h-auto w-full absolute top-0 left-0"
          onClick={handleGuidanceClick}
        ></img>
      );
    } else if (screenList.indexOf(screen) === 8 && guidance) {
      return (
        <img
          alt="img"
          src={guidance1}
          className="h-auto w-full absolute top-0 left-0"
          onClick={handleGuidanceClick}
        ></img>
      );
    } else if (screenList.indexOf(screen) === 9 && guidance) {
      return (
        <img
          alt="img"
          src={guidance2}
          className="h-auto w-full absolute top-0 left-0"
          onClick={handleGuidanceClick}
        ></img>
      );
    }
  };

  return (
    <div>
      <img alt="zooka" src={screen} className=" w-full " />
      <ClickRegions />
      <GuidanceRegion />
    </div>
  );
}

export default App;
